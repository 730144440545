import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["box", "region"]

  connect() {
    this.checkCheckbox()
  }

  checkCheckbox() {
    this.regionTarget.style.display = this.boxTarget.checked ? "block" : "none"
  }
}
