import {
  get,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill"
import { isPasskeySupportedAndAlert } from "./supported"

let getChallengeAndSubmitCredential = async function (form) {
  const csrfToken = document.getElementsByName("csrf-token")[0].content
  let credentialFieldName = form.dataset.credentialFieldName
  let newChallengeURL = new URL(form.dataset.challengeUrl)

  let challengeFetch = fetch(newChallengeURL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })

  const challengeJSON = await (await challengeFetch).json()
  const credentialAuthenticationOptions = parseRequestOptionsFromJSON({
    publicKey: challengeJSON,
  })

  const credentialAuthenticationResponse = await get(
    credentialAuthenticationOptions,
  )

  form.elements[credentialFieldName].value = JSON.stringify(
    credentialAuthenticationResponse,
  )
  debugger
  form.submit()
}

let submitFormEvent = async function (event) {
  event.preventDefault()
  event.stopImmediatePropagation()
  let form = event.currentTarget

  try {
    if (!(await isPasskeySupportedAndAlert())) {
      return
    }
    await getChallengeAndSubmitCredential(form)
  } catch (error) {
    console.error(
      "Error logging in with passkey",
      error.name,
      error.message,
      error,
    )
    if (error.name != "NotAllowedError") {
      alert(`Error creating passkey: ${error.name}: ${error.message}`)
    }
  }
}

export { submitFormEvent }
