import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "form"]

  connect() {
    this.labelTarget.style.cursor = "pointer"
    this.labelTarget.onclick = () => {
      this.labelTarget.style.display = "none"
      this.formTarget.style.display = "block"

      const input = this.formTarget.querySelector("input[type='text']")
      input.focus()
      input.select()
    }
  }
}
