import "./sentry.js"

import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"

import "../../assets/stylesheets/application.new.scss"

import "@fortawesome/fontawesome-free/js/all.js"
import "@fortawesome/fontawesome-free/css/all.css"

import "../controllers"

import "./direct_upload.js"

import "bootstrap/dist/js/bootstrap"
import "chartkick/chart.js"

import "./passkeys/bindings.js"

ActiveStorage.start()

window.toggleManualRegistration = function (event) {
  document.getElementById("manual-reg").style.display = "block"
  document.getElementById("manual-reg-link").style.display = "none"
  event.preventDefault()
}
