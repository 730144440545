import { submitFormEvent as createFormEvent } from "./create_passkey.js"
import { submitFormEvent as loginFormEvent } from "./login.js"
import { getReauthenticationToken } from "./passkey_reauthentication_handler.js"
import { isPasskeySupportedAndAlert } from "./supported.js"

document.addEventListener("turbo:load", () => {
  document
    .querySelector("#add-passkey-form")
    ?.addEventListener("submit", createFormEvent)

  document
    .querySelector("#passkey-login-form")
    ?.addEventListener("submit", loginFormEvent)

  let submitDeletePasskeyFormEvent = async function (event) {
    let form = event.currentTarget

    event.preventDefault()
    event.stopImmediatePropagation()

    if (!(await isPasskeySupportedAndAlert())) {
      return
    }

    if (
      !confirm(
        "Are you sure you want to delete this passkey? This action cannot be undone. You will first need to authenticate with an existing passkey.",
      )
    ) {
      return
    }

    try {
      await getReauthenticationToken(form)
      form.submit()
    } catch (error) {
      console.error("Error deleting passkey", error.name, error.message, error)
      if (error.name != "NotAllowedError") {
        alert(`Error creating passkey: ${error.name}: ${error.message}`)
      }
    }
  }

  document
    .querySelectorAll(".delete-passkey")
    .forEach((form) =>
      form.addEventListener("submit", submitDeletePasskeyFormEvent),
    )
})
