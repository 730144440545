addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML(
    "beforebegin",
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `,
  )
  target.previousElementSibling.querySelector(
    `.direct-upload__filename`,
  ).textContent = file.name
})

addEventListener("direct-upload:start", (event) => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", (event) => {
  const { id, progress, file } = event.detail
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`,
  )
  progressElement.style.width = `${progress}%`

  const progressText = `${formatBytes(
    file.size * (progress / 100),
  )} of ${formatBytes(file.size)}`

  const filenameField = progressElement.parentElement.querySelector(
    `.direct-upload__filename`,
  )
  filenameField.textContent = `${file.name} – ${progressText}`
})

addEventListener("direct-upload:error", (event) => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", (event) => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}
