export async function isPasskeySupported() {
  if (
    window.PublicKeyCredential &&
    typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable ===
      "function"
  ) {
    return await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
  }
  return false
}

export async function isPasskeySupportedAndAlert() {
  if (!(await isPasskeySupported())) {
    alert("Your browser does not support passkeys.")
    return false
  }
  return true
}
