import * as Sentry from "@sentry/browser"

const release = document.querySelector("html").getAttribute("data-release")

if (release) {
  Sentry.init({
    dsn: "https://4d39bd60f96a72530c947c8394abfcd9@o4505304993366016.ingest.us.sentry.io/4506950643744768",
    release: release,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  })
}
