import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill"
import { getReauthenticationToken } from "./passkey_reauthentication_handler.js"
import { isPasskeySupportedAndAlert } from "./supported.js"

let registerEvent = async function (event, form) {
  const csrfToken = document.getElementsByName("csrf-token")[0].content
  event.preventDefault()
  event.stopImmediatePropagation()

  form = form ?? event.currentTarget
  let data = new FormData(form)
  data.delete("_method")
  let credentialFieldName = form.dataset.credentialFieldName

  let newChallengeURL = new URL(form.dataset.challengeUrl)

  let challengeFetch = fetch(newChallengeURL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: data,
  })

  const challengeJSON = await (await challengeFetch).json()
  const credentialCreationOptions = parseCreationOptionsFromJSON({
    publicKey: challengeJSON,
  })

  const credentialCreationResponse = await create(credentialCreationOptions)

  form.elements[credentialFieldName].value = JSON.stringify(
    credentialCreationResponse,
  )
  form.submit()
}

let submitFormEvent = async function (event) {
  let form = event.currentTarget
  event.preventDefault()
  event.stopImmediatePropagation()

  if (!(await isPasskeySupportedAndAlert())) {
    return
  }

  try {
    if (form.dataset.requiresReauthentication == "true") {
      await getReauthenticationToken(form)
    }
    await registerEvent(event, form)
  } catch (error) {
    console.error("Error creating passkey", error.name, error.message, error)
    if (error.name != "NotAllowedError") {
      alert(`Error creating passkey: ${error.name}: ${error.message}`)
    }
  }
}

export { submitFormEvent }
